<script lang="ts">
	import cn from 'classnames';
	import cz_flag from '$lib/images/kosik/cz-flag.png';
	import sk_flag from '$lib/images/kosik/sk-flag.png';

	export let id: string;
	export let label: string;
	export let value: string | undefined = undefined;
	export let prefix: string | undefined = undefined;
	export let inputType: 'text' | 'email' | 'phone' | 'country' | 'textarea' | undefined = 'text';
	export let placeholder: string | undefined = undefined;
	export let selectedCountry: string | undefined = undefined;
	export let required: boolean | undefined = undefined;
	export let disabled: boolean | undefined = false;

	let cls =
		'w-full border-2 border-gray-geyser placeholder:text-gray-light md:placeholder:text-transparent py-4 px-5 md:col-span-3';
</script>

<div class="md:grid mt-2 md:grid-cols-4 md:items-center">
	<label for={id} class="mdmax:hidden">
		{label}
		{#if required}
			<sup class="text-red">*</sup>
		{/if}
	</label>

	{#if inputType === 'email'}
		<input {id} type="email" bind:value class={cn(cls, 'md:col-span-3')} placeholder={label} />
	{:else if inputType === 'phone'}
		<div class="flex grow gap-x-4 md:col-span-3">
			<div class={cn('flex max-w-36 items-center gap-x-4', cls)}>
				<img src={prefix === '+420' ? cz_flag : sk_flag} class="relative" alt="" />
				<select bind:value={prefix} class={cn('!border-transparent')}>
					<option value="+420">+420</option>
					<option value="+421">+421</option>
				</select>
			</div>
			<input {id} type="text" bind:value class={cn(cls, 'grow')} />
		</div>
	{:else if inputType === 'country'}
		<div class={cn(cls, 'flex grow gap-x-4 md:col-span-3')}>
			<img src={selectedCountry === 'CZ' ? cz_flag : sk_flag} class="relative" alt="" />
			<select class="grow" bind:value={selectedCountry} {disabled}>
				<option value="CZ" class="relative"> Česká republika</option>
				<option value="SK">Slovenská republika</option>
			</select>
		</div>
		{#if disabled}
			<span
				>Nelze změnit! Máte zvolenou {#if selectedCountry === 'CZ'}Českou{:else}Slovenskou{/if} dopravu.
				Můžete zvolit jinou <a class="text-red" href="/kosik/doprava-a-platba">zde</a>.</span
			>
		{/if}
	{:else if inputType === 'textarea'}
		<textarea
			class={cn(cls, 'md:col-span-3')}
			rows="5"
			bind:value
			placeholder={placeholder ? placeholder : label}
		/>
	{:else}
		<input {id} type="text" bind:value class={cn(cls)} placeholder={label} />
	{/if}
</div>
